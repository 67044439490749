<template>
  <div>
    <van-cell-group title="筛选">
      <van-cell title="统计周期" :value="date.val" @click="date.show=true" is-link />
      <van-calendar v-model="date.show" type="range" @confirm="dateSel" :show-confirm="false" :allow-same-day="true"
        :min-date="new Date(2023,0,1)" />
    </van-cell-group>
    <van-cell-group title="相关数量" :border="false">
      <van-row style="padding:10px;" :gutter="11">
        <van-col :span="6">
          <div class="fr_cnt" :style="{backgroundImage:'url('+bg_url[0]+')'}">
            <p>{{model.CNT}}</p>
            <p>总数量</p>
          </div>
        </van-col>
        <van-col :span="6">
          <div class="fr_cnt" :style="{backgroundImage:'url('+bg_url[1]+')'}">
            <p>{{model.CNT_FINISH}}</p>
            <p>完成量</p>
          </div>
        </van-col>
        <van-col :span="6">
          <div class="fr_cnt" :style="{backgroundImage:'url('+bg_url[2]+')'}">
            <p>{{model.CNT_UNFINISH}}</p>
            <p>未完成量</p>
          </div>
        </van-col>
        <van-col :span="6">
          <div class="fr_cnt" :style="{backgroundImage:'url('+bg_url[3]+')'}">
            <p>{{model.COST}}</p>
            <p>总费用</p>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-cell-group title="提交人统计">
      <van-cell v-for="it in model.LST_SUB" :key="it.NAME" :title="it.NAME" :value="it.CNT" />
    </van-cell-group>
    <van-cell-group title="维修人图表">
      <div ref="bar_mender" style="height: 300px;width: 400px;"></div>
    </van-cell-group>
  </div>
</template>
<style scoped>
  .fr_cnt {
    height: 43px;
    background-size: contain;
    width: 100%;
    font-size: 14px;
    padding-top: 57px;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center
  }

  .fr_cnt p {
    margin: 0px;
    line-height: 20px;
  }

  .chart-container div {
    width: 100%;
  }
</style>
<script>
  import moment from "moment";
  import * as echarts from 'echarts'
  export default {
    name: "report",
    components: {},
    data() {
      return {
        sea: {
          DTS: moment().format('YYYY-01-01'),
          DTE: moment().format('YYYY-12-31')
        },
        date: {
          show: false,
          val: ''
        },
        model: { LST_MENDER: [0] },
        chart: {
          dat_bar: []
        },
        bg_url: [
          require('@/assets/fix/rep_all.png'),
          require('@/assets/fix/rep_finish.png'),
          require('@/assets/fix/rep_unfinish.png'),
          require('@/assets/fix/rep_cost.png')
        ]
      }
    },
    created() {
      this.date.val = this.sea.DTS + ' 至 ' + this.sea.DTE;
      this.getReport();
    },
    methods: {
      dateSel(d) {
        this.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
        this.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
        this.date.show = false;
        this.date.val = this.sea.DTS + ' 至 ' + this.sea.DTE;
        this.filter();
      },
      filter() {
        this.getReport();
      },
      getReport() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/NoteApi/Report",
          data: self.sea,
          completed: function (m) {
            self.model = m.DATA;
            if (m.DATA.LST_MENDER.length > 0)
              self.drawBar(m.DATA.LST_MENDER);
          }
        })
      },
      drawBar(dat) {
        let x = dat.map(o => o.NAME)
        let y = dat.map(o => o.CNT)
        let mychart = echarts.init(this.$refs.bar_mender)
        mychart.setOption({
          grid: {
            left: '5%',
            right: '5%',
            top: '10%',
            bottom: '5%',
            containLabel: true
          },
          xAxis: {
            data: x,
            axisLabel: {
              rotate: 90,
              margin: 20
            },
            axisTick: {
              alignWithLabel: true
            }
          },
          yAxis: {
            axisLabel: {
              show: false
            }
          },
          series: [{
            type: 'bar',
            data: y,
            barWidth: '20px',
            colorBy: 'data',
            label: {
              show: true,
              position: 'top'
            }
          }]
        })
        window.addEventListener('resize', mychart.resize());
      }
    }
  }
</script>