import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=02da0ac8&scoped=true&"
import script from "./edit.vue?vue&type=script&lang=js&"
export * from "./edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02da0ac8",
  null
  
)

export default component.exports