<template>
  <div>
    <van-cell-group title="流程">
      <van-cell title="新增维修" is-link size="large" icon="plus" to="/fix/note/new" />
      <van-cell title="分配工单" is-link size="large" icon="exchange" :to="{path:'/fix/note/pro',query:{sta:1}}"
        v-if="mender_role=='ADMIN'" />
      <van-cell title="维修处理" is-link size="large" icon="brush-o" :to="{path:'/fix/note/pro',query:{sta:5}}"
        v-if="mender_role=='REPAIR' || mender_role=='ADMIN' " />
      <van-cell title="验收工作" is-link size="large" icon="success" :to="{path:'/fix/note/pro',query:{sta:7}}" />
    </van-cell-group>
    <van-cell-group title="查询">
      <van-cell title="我的记录" is-link size="large" icon="user-circle-o" to="/fix/note/list?for=MINE" />
      <van-cell title="全部记录" is-link size="large" icon="search" to="/fix/note/list" v-if="mender_role=='ADMIN'" />
      <van-cell title="统计报告" is-link size="large" icon="chart-trending-o" to="/fix/note/report" v-if="show" />
    </van-cell-group>
  </div>
</template>

<script>
  export default {
    name: "index",
    data() {
      return {
        mender_role: '',
        user_role: '',
        show: false
      }
    },
    created() {
      this.getMenders();
    },
    methods: {
      getMenders() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/FIX/MenderApi/GetList",
          completed: function (its, n) {
            let user = self.whale.user.get();
            self.user_role = user.ROLE;
            for (let i = 0; i < n; i++) {
              let o = its.ITEMS[i];
              if (o.TEA_ID == user.ID) {
                self.mender_role = o.ROLE;
                break;
              }
            }
            self.show = self.user_role == 'ADMIN' || self.mender_role == 'REPAIR' || self.mender_role == 'ADMIN';
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>