<template>
  <div>
    <van-empty description="暂无数据" v-if="grid.lst.length == 0" />
    <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading">
      <div v-for="item in grid.lst" :key="item.ID" class="card">
        <div @click="onActionTrigger(item)">
          <van-row>
            <van-col :span="3"><b>编号:</b></van-col>
            <van-col :span="9">{{ item.SN }}</van-col>
            <van-col :span="3"><b>位置:</b></van-col>
            <van-col :span="9">{{ item.LOC_NAME }}</van-col>
          </van-row>
          <van-row>
            <van-col :span="3"><b>名称:</b></van-col>
            <van-col :span="9">{{ item.CAT_NAME }}</van-col>
            <van-col :span="3"><b>状态:</b></van-col>
            <van-col :span="9" style="color:#3aa8ec">{{ item.STA_TXT }}</van-col>
          </van-row>
          <van-row>
            <van-col :span="3"><b>提交:</b></van-col>
            <van-col :span="9">{{ item.SUB_NAME }} / {{ item.CREATED_DT | datetime('YYYY-MM-DD') }}</van-col>
            <van-col :span="3"><b>维修:</b></van-col>
            <van-col :span="9">
              {{ item.MENDER }}
              <span v-if="item.STA == 7"> / {{ item.FINISH_DT | datetime('YYYY-MM-DD') }}</span>
            </van-col>
          </van-row>
          <van-divider />
          <van-row>
            <van-col :span="24">
              <b>问题:</b>{{ item.DESCRIPTION }}
            </van-col>
          </van-row>
          <van-row v-if="item.MEM">
            <van-col :span="24">
              <b>备注:</b>{{ item.MEM }}
            </van-col>
          </van-row>
        </div>
        <van-divider />
        <van-row v-if="item.PIC_NAME">
          <van-col :span="24">
            <!-- <van-image :src="item.PIC_URL" @click="prvImage(item.PIC_URL, item.PIC_URL_FINISH)" /> -->
            <van-image v-for="img in item.ImageKvs" :src="img.Value" :key="img.Key" @click="prvImage(img.Value, item.PIC_URL_FINISH)" />
          </van-col>
        </van-row>
      </div>
    </van-list>
    <!--Actions-->
    <div>
      <van-popup v-model="action.show" position="bottom">
        <div class="action_btn" v-if="action.model.STA == 1">
          <van-button text="分配维修人" size="large" type="primary" icon="exchange" @click="onDeliver" />
        </div>
        <div class="action_btn" v-if="action.model.STA == 5">
          <van-button text="完成维修" size="large" type="info" icon="brush-o" @click="onRepair" />
        </div>
        <div class="action_btn" v-if="action.model.STA == 7">
          <van-button text="验收工作" size="large" type="danger" icon="success" @click="onConfirm" />
        </div>
        <div class="action_btn" v-if="action.model.STA < 7">
          <van-button text="撤 消" size="large" type="warning" icon="close" @click="cancel" />
        </div>
      </van-popup>
    </div>
    <!--Deliver-->
    <div>
      <van-popup v-model="deliver.show" position="bottom" round>
        <van-cell title="编号" :value="action.model.SN" />
        <van-radio-group v-model="deliver.id">
          <van-cell-group title="维修人">
            <van-cell v-for="it in mender.repair" :key="it.ID" :title="it.NAME" clickable @click="deliver.id = it.ID">
              <template #right-icon>
                <van-radio :name="it.ID" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell-group title="抄送人">
          <van-cell v-for="it in mender.copy" :key="it.ID" :title="it.NAME">

            <template #right-icon>
              <van-checkbox v-model="it.CK" shape="square" />
            </template>
          </van-cell>
        </van-cell-group>
        <van-field type="text" v-model="deliver.name" placeholder="如果是外部人员，请输入姓名" @input="onOutInput">

          <template #label>
            <span style="font-weight: bold">外部人员</span>
          </template>
        </van-field>
        <div style="padding:10px 50px">
          <van-button type="primary" size="large" round text="确认提交" :loading="deliver.submitting" loading-text="正在提交"
            @click="onDelivered" />
        </div>
      </van-popup>
    </div>
    <!--Repaired-->
    <div>
      <van-popup v-model="repaired.show" position="bottom" round>
        <van-cell title="编号" :value="action.model.SN" />
        <van-field type="number" label="维修费用" v-model="action.model.COST" />
        <van-field v-model="action.model.MEM" type="textarea" rows="3" autosize show-word-limit maxlength="100"
          label="维修结果" placeholder="请输入相关问题的详细说明" />
        <div style="text-align: left">
          <van-cell-group title="上传图片">
            <van-uploader :after-read="afterRead" v-model="repaired.picList" max-count="1"
              style="margin: 10px 0px 0px 10px" />
          </van-cell-group>
        </div>

        <div class="sign">
          <div class="sign-tit">签名</div>
          <sign-canvas v-if="!signUrl" class="sign-canvas" ref="SignCanvas" :options="options" v-model="value" />
          <img v-if="signUrl" :src="signUrl" alt="">
          <div class="btns">
            <van-button type="danger" size="small" @click="canvasClear()" style="margin-right: 20px;">清空</van-button>
            <van-button type="primary" size="small" @click="saveAsImg()">保存</van-button>
          </div>
        </div>

        <div style="padding:10px 50px">
          <van-button type="primary" size="large" round text="确认提交" :loading="deliver.submitting" loading-text="正在提交"
            @click="onRepaired" />
        </div>
      </van-popup>
    </div>
    <!--Confirmed-->
    <div>
      <van-popup v-model="confirm.show" position="bottom" round>
        <van-cell title="编号" :value="action.model.SN" />
        <van-field type="number" label="维修费用" v-model="action.model.COST" />
        <van-field v-model="action.model.MEM" type="textarea" rows="3" autosize show-word-limit maxlength="100"
          label="维修结果" placeholder="请输入相关问题的详细说明" />
        <div>
          <div style="padding:10px 50px">
            <van-button type="primary" size="large" round text="确认合格" :loading="confirm.submitting" loading-text="正在提交"
              @click="onConfirmed(8)" />
          </div>
          <div style="padding:10px 50px">
            <van-button type="danger" size="large" round text="返工重修" :loading="confirm.submitting" loading-text="正在提交"
              @click="onConfirmed(5)" />
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import { ImagePreview, Dialog, Toast } from 'vant';
  export default {
    components: { [ImagePreview.Component.name]: ImagePreview.Component },
    data() {
      return {
        mender: {
          repair: [],
          copy: []
        },
        grid: {
          sea: {
            PAGE_SIZE: 5,
            PAGE_INDEX: -1,
            FOR: this.$route.query.for,
            STA: this.$route.query.sta
          },
          loading: false,
          finish: false,
          lst: []
        },
        action: {
          show: false,
          model: {}
        },
        deliver: {
          show: false,
          id: 0,
          name: '',
          submitting: false,
        },
        repaired: {
          show: false,
          picList: [],
        },
        confirm: {
          show: false,
          submitting: false,
        },

        rate: {
          show: false,
          score: 0,
          id: 0
        },

        value: '',
        options: {
          lastWriteSpeed: 1, //书写速度 [Number] 可选
          lastWriteWidth: 2, //下笔的宽度 [Number] 可选
          lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
          lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
          canvasWidth: 300, //canvas宽高 [Number] 可选
          canvasHeight: 120, //高度  [Number] 可选
          isShowBorder: true, //是否显示边框 [可选]
          bgColor: "#fff", //背景色 [String] 可选
          borderWidth: 1, // 网格线宽度  [Number] 可选
          borderColor: "#ccc", //网格颜色  [String] 可选
          writeWidth: 5, //基础轨迹宽度  [Number] 可选
          maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
          minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
          writeColor: "#101010", // 轨迹颜色  [String] 可选
          isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
          imgType: "png" //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
        },
        signUrl: ''
      }
    },
    created() {
      if (this.$route.query.sta == 1) {
        document.title = '待分配记录'
      } else {
        document.title = '待维修记录'
      }
      this.getMenders();

    },
    methods: {
      getMenders() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/FIX/MenderApi/GetList",
          completed: function (its) {
            its.ITEMS.map(o => {
              if (o.ROLE == "REPAIR") self.mender.repair.push(o);
              else if (o.ROLE == "COPY") {
                o.CK = false;
                self.mender.copy.push(o);
              }
            });
          }
        })
      },
      filter() {
        this.grid.lst.length = 0;
        this.grid.finish = false;
        this.grid.sea.PAGE_INDEX = 0;
        this.getList();
      },
      loadPage() {
        this.grid.sea.PAGE_INDEX += 1;
        this.getList();
      },
      getList() {
        let self = this;
        self.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/Mobile/FIX/NoteApi/GetList",
          data: this.grid.sea,
          completed(its, n) {
            self.grid.lst.push(...its.ITEMS);
            self.grid.loading = false;
            if (self.grid.lst.length >= n) self.grid.finish = true;
          }
        })
      },
      prvImage(url1, url2) {
        let us = [];
        if (url1 != undefined) us.push(url1)
        if (url2 != undefined) us.push(url2)
        ImagePreview(us)
      },
      onActionTrigger(obj) {
        this.action.show = true;
        this.action.model = obj;
      },
      onDeliver() {
        this.action.show = false;
        Object.assign(this.deliver, { show: true, submitting: false, id: 0, name: '' })
      },
      onOutInput() {
        this.deliver.id = 0;
      },
      onDelivered() {
        let self = this;
        let menders = this.mender.copy.filter(o => o.CK);
        if (this.deliver.id == 0) {
          if (this.deliver.name == '') {
            Toast.fail("请选择一个维修人")
            return
          } else {
            menders.push({ NAME: this.deliver.name, ROLE: 'REPAIR' });
          }
        } else {
          menders.push(...this.mender.repair.filter(o => o.ID == this.deliver.id));
        }
        this.deliver.submitting = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/NoteApi/Deliver",
          data: {
            ID: this.action.model.ID,
            MENDERS: menders
          },
          finally() { self.deliver.submitting = false },
          completed() {
            self.deliver.show = false;
            self.filter();
          }
        })
      },
      onRepair() {
        this.action.show = false;
        Object.assign(this.repaired, { show: true, submitting: false, picList: [] })
        this.getSign()
      },
      onRepaired() {
        if (this.signUrl === '') {
          Toast.fail('请先保存签名！')
          return
        }
        let n = this.repaired.picList.length > 0;
        if (n > 0) {
          let temp = this.repaired.picList[0].content;
          let arr = temp.split(',')
          this.action.model.PIC_B64 = arr[1];
        } else
          this.action.model.PIC_B64 = '';
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/NoteApi/Repaired",
          data: this.action.model,
          finally() { },
          completed() {
            self.repaired.show = false;
            self.filter();
          }
        })
      },
      afterRead(file) {
        this.repaired.picList = [file];
      },
      onConfirm() {
        this.action.show = false;
        Object.assign(this.confirm, { show: true, submitting: false })
      },
      onConfirmed(sta) {
        let self = this;
        self.action.model.STA = sta;
        this.confirm.submitting = true;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/NoteApi/Confirmed",
          data: this.action.model,
          finally() { self.confirm.submitting = false },
          completed() {
            self.confirm.show = false;
            self.filter();
          }
        })
      },
      cancel() {
        let self = this;
        Dialog.confirm({
          title: "撤消维修",
          message: "真的要撤消这次维修吗？",
        }).then(() => {
          this.whale.remote.getResult({
            url: "/api/Mobile/FIX/NoteApi/Cancel",
            data: {
              ID: self.action.model.ID,
            },
            finally() { },
            completed() {
              self.action.show = false;
              self.filter();
            }
          })
        })
      },

      // 
      canvasClear() {
        if (this.signUrl) {
          this.signUrl = ''
        } else {
          this.$refs.SignCanvas.canvasClear();
        }
      },
      // 保存图片
      saveAsImg() {
        const img = this.$refs.SignCanvas.saveAsImg();
        console.log(img)
        if (img) {
          const parts = img.split(";base64,");
          console.log("parts", parts);
          const contentType = parts[0].split(":")[1];
          const raw = window.atob(parts[1]);
          console.log("raw", raw);
          const rawLength = raw.length;
          const uInt8Array = new Uint8Array(rawLength);

          // 将 Base64 转换为 Uint8Array
          for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
          }

          // 创建 Blob 对象
          let blob = new Blob([uInt8Array], { type: contentType });
          console.log(blob);
          this.H5SignUploadPost(blob);
        } else {
          this.$message.warning('没有签名')
        }
      },
      H5SignUploadPost(blob) {
        const formData = new FormData();
        formData.append("file", blob, "sign.png");
        let self = this;
        this.whale.remote.uploadSign({
          url: 'api/Mobile/FIX/NoteApi/SignPcUpLoad',
          data: formData,
          completed: function (resp) {
            self.signUrl = resp.Data.DATA.OSS_URL
            self.action.model.MENDER_SIGN_PIC_NAME = resp.Data.DATA.NAME
            console.log('sign', self.signUrl)
          }
        })
      },
      getSign() {
        let self = this
        self.whale.remote.getResult({
          url: 'api/Mobile/FIX/NoteApi/GetSignPc',
          completed(res) {
            console.log('12', res)
            self.signUrl = res.OSS_URL
            self.action.model.MENDER_SIGN_PIC_NAME = res.NAME
          }
        })
      }
    }
  }
</script>

<style scoped>
  .van-row {
    line-height: 24px
  }

  .card {
    margin: 10px;
    border-radius: 15px;
    background: #fff;
    padding: 10px;
    font-size: 14px;
  }

  .card div b {
    color: #999;
    font-weight: normal;
    margin-right: 5px
  }

  .pro {
    padding: 5px
  }

  .pro button {
    margin: 0px 5px;
    width: 90px;
  }

  .mender {
    border-bottom: solid 1px #ebedf0;
    text-align: left;
    padding-left: 5px;
  }

  .mender span {
    margin: 5px;
  }

  .action_btn {
    padding: 15px
  }

  .sign .sign-tit {
    padding: 16px 16px 8px;
    color: #969799;
    font-size: 14px;
    line-height: 16px;
  }

  .sign .sign-canvas {
    display: block;
    margin: 0 auto;
  }

  .sign img {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 120px;
  }

  .sign .btns {
    margin-top: 10px;
    text-align: center;
  }
</style>