<template>
  <div class="center">
    <div class="detail">
      <div class="item">
        <div class="title">
          <div class="titleL">
            <span>申请信息</span>
          </div>
          <div class="titleR"></div>
        </div>
        <div class="content">
          <div class="span">来访日期</div>
          <div class="info">{{ detail.Visite_Date }} </div>
        </div>
        <div class="content">
          <div class="span">来访原因</div>
          <div class="info">{{ detail.Reason }}</div>
        </div>
        <div class="content">
          <div class="span">申请人</div>
          <div class="info">{{ detail.Visitor_Name }}</div>
        </div>
        <div class="content">
          <div class="span">性别</div>
          <div class="info">{{ detail.Visitor_Sex }}</div>
        </div>
        <div class="content">
          <div class="span">联系电话</div>
          <div class="info">{{ detail.Visitor_Mobile }}</div>
        </div>
        <div class="content">
          <div class="span">身份证号</div>
          <div class="info">{{ detail.Visitor_Id_Numer }}</div>
        </div>
        <div class="content">
          <div class="span">工作单位</div>
          <div class="info">{{ detail.Visitor_Work_Place }}</div>
        </div>
        <div class="content">
          <div class="span">被访问人</div>
          <div class="info">{{ detail.Be_Visited_Name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      detail: JSON.parse(sessionStorage.getItem('visitDetail')),
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.detail {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  padding-bottom: 140px;

  .top {
    display: flex;
    padding: 18px 3px 25px 12px;
    justify-content: space-between;
    border-bottom: 1px solid #efeff2;
  }

  .top {
    .left {
      display: flex;
      align-items: center;

      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .stuInfo {
        padding-left: 14px;

        .name {
          font-size: 19px;
          color: #35446c;
          font-weight: bold;
        }

        .className {
          font-size: 15px;
          color: #a3a3a3;
        }
      }
    }
  }

  .item {
    margin: 22px 0 40px;

    .title {
      display: flex;
      align-items: center;

      .titleL {
        flex: 0 0 auto;
        display: inline-block;
        text-align: center;
        min-width: 5em;
        border-radius: 40px;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        padding: 5px 10px;
        box-sizing: border-box;
        background: #53c70c;
        margin-right: 10px;

        span {
          padding-left: 6px;
          font-weight: bold;
        }
      }

      .titleR {
        flex: 1;
        margin-left: 9px;
        border-top: 2px dotted #f3f3f3;
      }

      .more {
        width: 64px;
        height: 37px;
        background: #f6f7fb;
        border-radius: 50px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .gd {
          width: 34px;
          height: 7px;
        }
      }

    }

    .content {
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 16px;

      .span {
        min-width: 5em;
        flex: 0 0 auto;
        position: relative;
        padding-left: 25px;
        color: #1e1e1e;
        display: inline-block;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          background: #53c70c;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
        }
      }

      .info {
        margin: 0 15px;
        color: #626269;
        text-align: right;
        .tipImg {
          width: 200px;
          height: 200px;
        }
      }

      .before {
        color: #c6c6c6 !important;
      }

      .yesList {
        color: #04b0f1 !important;
        /*font-weight: bold;*/
      }

      .noList {
        color: #ff2222 !important;
      }
    }

  }
}

.btns {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .btn {
    flex: 1;
    margin: 0 20px;
    border-radius: 50px;
    height: 80px;
    font-size: 16px;
  }
}

.textarea {
  border: 1px solid #e2e2e5;
  margin: 20px 40px 40px;
}

.ewmBox {
  background: #fff;
  padding: 50px;

  .ewm {
    width: 400px;
    height: 400px;
  }
}

.moreWrap {
  .moreItem {
    text-align: center;
    padding: 0 20px;
    color: #000;
    font-size: 16px;
    padding: 30px 0;

    +.moreItem {
      border-top: 1px solid #f6f7fb;
    }
  }
}
</style>
