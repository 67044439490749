<template>
  <div>
    <van-cell-group title="基本信息">
      <van-cell title="学校" v-model="model.SCHOOL.FULL_NAME" size="large" />
      <van-cell title="姓名" v-model="model.NAME" size="large" />
      <van-cell title="手机" v-model="model.MOBILE" size="large" />
      <van-cell title="角色" v-model="model.ROLE" size="large" />
    </van-cell-group>
    <div class="sign">
      <div class="sign-tit"></div>
      <sign-canvas v-if="!sign.urlKv" class="sign-canvas" ref="signCanvas" :options="options" />
      <img v-if="sign.urlKv" :src="sign.urlKv.Value" alt="">
      <div class="btns">
        <van-button type="danger" size="small" @click="canvasClear()" style="margin-right: 20px;">清空</van-button>
        <van-button type="primary" size="small" @click="saveAsImg()">保存</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant';
  export default {
    data() {
      return {
        model: { SCHOOL: {} },
        value: "",
        sign: {
          urlKv: {},
        },
        options: {
          lastWriteSpeed: 1, //书写速度 [Number] 可选
          lastWriteWidth: 2, //下笔的宽度 [Number] 可选
          lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
          lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
          canvasWidth: 300, //canvas宽高 [Number] 可选
          canvasHeight: 120, //高度  [Number] 可选
          isShowBorder: true, //是否显示边框 [可选]
          bgColor: "#fff", //背景色 [String] 可选
          borderWidth: 1, // 网格线宽度  [Number] 可选
          borderColor: "#ccc", //网格颜色  [String] 可选
          writeWidth: 5, //基础轨迹宽度  [Number] 可选
          maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
          minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
          writeColor: "#101010", // 轨迹颜色  [String] 可选
          isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
          imgType: "png" //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
        },
      }
    },
    created() {
      this.getMine()
    },
    methods: {
      getMine() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/ORG/TeacherApi/Mine",
          completed(m) {
            self.model = m.DATA;
            self.sign.urlKv = m.DATA.SING_KV;
          }
        })
      },

      canvasClear() {
        if (this.sign.urlKv) {
          this.sign.urlKv = null
        } else
          this.$refs.signCanvas.canvasClear();
      },
      saveAsImg() {
        const img = this.$refs.signCanvas.saveAsImg();
        if (img) {
          const parts = img.split(";base64,");
          this.whale.remote.getResult({
            url: "/api/Mobile/ORG/TeacherApi/UpdateSign",
            data: { TID: this.model.ID, SIGN64: parts[1] },
            completed(m) {
              console.log(m);
              Toast.success('保存成功')
            }
          })

        } else {
          Toast.fail('没有签名')
        }
      },
    }
  }
</script>

<style scoped>
  .sign .sign-tit {
    padding: 16px 16px 8px;
    color: #969799;
    font-size: 14px;
    line-height: 16px;
  }

  .sign .sign-canvas {
    display: block;
    margin: 0 auto;
  }

  .sign img {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 120px;
  }

  .sign .btns {
    margin-top: 10px;
    text-align: center;
  }
</style>