<template>
  <div>
    <div>
      <van-cell-group title="请填写基础信息以方便维修">
        <van-field v-model="model.CAT_NAME" is-link readonly label="名称" @click="cat.show=true" />
        <van-field v-model="model.LOC_NAME" is-link readonly label="位置" @click="loc.show=true" />
        <van-field v-model="model.DESCRIPTION" type="textarea" rows="3" autosize show-word-limit maxlength="100"
          label="问题描述" placeholder="请输入相关问题的详细说明" />

      </van-cell-group>
      <van-cell-group title="上传图片 (最多 3 张)">
        <van-uploader :after-read="afterRead" v-model="picList" @delete="delImage" max-count="3"
          style="margin: 10px 0px 0px 10px" />
      </van-cell-group>
      <div style="margin: 15px">
        <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
          loading-text="正在提交。。。">确认提交</van-button>
        <br />
        <van-button plain type="default" size="large" icon="cross" block to="/fix">取消返回</van-button>
      </div>
    </div>
    <div>
      <van-popup v-model="cat.show" round position="bottom">
        <van-cascader v-model="model.CAT_ID" :options="cat.ls" title="请选择分类" @close="cat.show=false"
          @finish="onCatFinished" :field-names="{text:'NAME',value:'ID',children:'CHILDREN'}" />
      </van-popup>
      <van-popup v-model="loc.show" round position="bottom">
        <van-cascader v-model="model.LOC_ID" :options="loc.ls" title="请选择分类" @close="loc.show=false"
          @finish="onLocFinished" :field-names="{text:'NAME',value:'ID',children:'CHILDREN'}" />
      </van-popup>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        model: {},
        cat: {
          show: false,
          ls: []
        },
        loc: {
          show: false,
          ls: []
        },
        picList: [],
        ret: {
          loading: false
        },
        base64List: []
      }
    },
    created() {
      this.getCat();
      this.getLoc();
    },
    methods: {
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/CatApi/GetTree",
          completed: function (its) {
            self.cat.ls = its.DATA;
          }
        })
      },
      onCatFinished(sel) {
        this.cat.show = false;
        let n = sel.selectedOptions.length;
        if (n > 0) this.model.CAT_NAME = sel.selectedOptions[n - 1].FULL_NAME;
      },
      getLoc() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/FIX/LocationApi/GetTree",
          completed: function (its) {
            self.loc.ls = its.DATA;
          }
        })
      },
      onLocFinished(sel) {
        this.loc.show = false;
        let n = sel.selectedOptions.length;
        if (n > 0) this.model.LOC_NAME = sel.selectedOptions[n - 1].FULL_NAME;
      },
      afterRead(f) {
        let base64 = f.content.split(",")[1]
        this.base64List.push(base64)
        // let self = this;
        // this.whale.image.compress({
        //   file: f.file,
        //   callback(f) {
        //     self.whale.image.toB64({
        //       file: f,
        //       callback(b64) {
        //         // self.model.PIC_B64 = b64
        //         self.base64List.push(b64)
        //       }
        //     })
        //   }
        // })
      },
      delImage(f) {
        let base64 = f.content.split(",")[1]
        this.base64List = this.base64List.filter(item => item != base64)
      },
      submit() {
        let self = this;
        self.ret.loading = true;
        self.model.PIC_B64 = self.base64List.join(",");
        this.whale.remote.getResult({
          url:"/api/Mobile/FIX/NoteApi/Save",
          data:this.model,
          finally(){
            self.ret.loading=false;
          },
          completed(){
            self.$dialog.confirm({
              title:"完成",
              message:"维修记录已成功提交，请等待处理！",
              theme:"round",
              confirmButtonText:"继续录入",
              cancelButtonText:"返回"
            }).then(()=>{
              self.$router.go(0);
            }).catch(()=>{
              self.$router.push("/fix")
            })
          }
        })
      }
    }
  }
</script>
<style scoped></style>