<template>
  <div>
    <van-cell-group title="会议信息">
      <van-cell title="会议主题" :value="model.TITLE" />
      <van-cell title="会议地点" :value="model.LOC" />
      <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" />
      <van-cell title="主持人" :value="model.HOST" />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="主要内容">
          {{model.CONT}}
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>

    <van-cell-group title="请假/缺席">
      <van-notice-bar left-icon="volume-o" text="提示：如果您需要请假缺席，请填写以下信息并提交。" />
      <van-cell title="我要请假">
        <template #right-icon>
          <van-checkbox v-model="checked" />
        </template>
      </van-cell>
      <van-field label="请假原因" type="text" placeholder="请输入请假理由" v-model="desc" />
    </van-cell-group>
    <van-cell center title="是否启用签名">
      <template #right-icon>
        <van-switch v-model="sign.show" size="24" @change="getSign" />
      </template>
    </van-cell>
    <div class="sign">
      <van-image class="img" v-if="sign.show" :src="sign.urlKv.Value" />
    </div>

    <div style="margin: 35px 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting"
        loading-text="正在提交。。。" :disabled="!checked">确认提交</van-button>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant';
  export default {
    name: "sign",
    data() {
      return {
        model: {},
        submitting: false,
        activeNames: [],
        checked: false,
        desc: '',
        sign: {
          show: false,
          urlKv: {}
        }
      }
    },
    created() {
      this.get();
    },
    methods: {
      get() {
        let self = this;
        let g = this.$route.query.guid;
        if (g !== undefined) {
          this.whale.remote.getResult({
            url: "/api/Mobile/MEET/MinfoApi/Get",
            data: { GUID: g },
            completed(m) {
              self.model = m.DATA;
            }
          })
        }
      },
      getSign(e) {
        if (e) {
          this.sign.urlKv = this.whale.user.get().SING_KV;
          if (this.sign.urlKv == null) {
            Toast.fail('请先去个人中心签名');
            this.sign.show = false;
          }
        }
      },
      submit() {
        let self = this;
        self.whale.remote.getResult({
          url: "/api/Mobile/MEET/MJoinApi/Absent",
          data: {
            GUID: self.model.GUID,
            DESC: self.desc,
            SIGN: self.sign.show ? self.sign.urlKv.Key : ''
          },
          completed() {
            self.$dialog.alert({
              message: "记录已成功提交！"
            }).then(() => {
              self.$router.push('/')
            })
          }
        })
      }
    }
  }
</script>
<style scoped>
  .sign {
    padding: 16px 16px 8px;
    color: #969799;
    font-size: 14px;
    line-height: 16px;
  }

  .sign .img {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 120px;
  }
</style>